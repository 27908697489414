import { computed, ref } from '@vue/composition-api'

export const defaultPlaceholder = require('@/assets/placeholder.png')

export const useImagePlaceholder = (images?: string[]) => {
  const placeholderIndex = ref(0)

  const nextPlaceholder = () => (placeholderIndex.value += 1)

  const placeholders = (images || []).filter(Boolean)

  return {
    placeholders,
    currentPlaceholder: computed(() => placeholders[placeholderIndex.value] || defaultPlaceholder),
    nextPlaceholder
  }
}
